<template>
  <v-app>
    <div v-if="isAuthenticated" data-app>
      <dashboard-core-app-bar v-model="expandOnHover" />

      <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

      <dashboard-core-view />

      <dashboard-core-settings v-model="expandOnHover" />
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    DashboardCoreSettings: () => import("./components/core/Settings"),
    DashboardCoreView: () => import("./components/core/View"),
  },
  data: () => ({
    expandOnHover: false,
  }),
  computed: {
    ...mapGetters(["isAuthenticated"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
  },

  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
  },

  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "Login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
};
</script>
